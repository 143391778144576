:root {
    --background-gradient: linear-gradient(30deg, #f39c12 30%, #f1c40f);
    --gray: #34495e;
    --darkgray: #2c3e50;
    --c-gray-600: #313131;
    --c-text-tertiary: var(--c-gray-500);
    --c-gray-200: #f1f1f1;
    
  }
  
  select {
    /* Reset Select */
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    /* Personalize */
    border-bottom: 1px solid var(--c-gray-200);
    flex: 1;
    padding: 0 1em;
   color: var(--c-text-tertiary);
    background-color: transparent;
    background-image: none;
    cursor: pointer;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select wrapper */
  .select {
    position: relative;
    display: flex;
    /* width: 13em; */
    height: 3em;
    border-radius: .25em;
    overflow: hidden;
  }
  /* Arrow */
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    background-color: transparent;
    transition: .25s all ease;
    pointer-events: none;
    color: white;
  }
  /* Transition */
  .select:hover::after {
    color: #f39c12;
  }
  


  