.side-navbar {
    width: 250px;
    height: 100%;
    position: fixed;
    margin-left: -300px;
    background-color: #1f1f1f;
    transition: 0.5s;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #ffffff26;
}

.my-container-change {
    transition: 0.4s;
}

.active-nav {
    margin-left: 0;
}

/* for main section */
.active-cont {
    margin-left: 250px;
}

#menu-btn {
    background-color: #100901;
    color: #fff;
    margin-left: -62px;
}

.my-container-change input {
    border-radius: 2rem;
    padding: 2px 20px;
    /* color: #fff; */
}

.MuiFormControl-root{
    color:white !important;
    /* background-color:red !important; */
    border:1px solid white !important;
    /* margin-left: 1rem !important; */


}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    color:white !important
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    color:white !important
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
    color:white !important
}