.navbar {
  height: 50px;
  margin: 2px;
  .navbar-cont {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  // background-color: aquamarine;
  .Heading {
    display: flex;
    align-items: center;

    // font-size: 1.7rem;
    h3 {
      color: gray;
      font-family: "fantasy";
      text-align: center;
      margin-left: 10px;
      font-size: xx-large;
    }
  }
  .nav-icons {
    display: flex;

    .nav-icon {
      font-size: 30px;
      color: coral;
      cursor: pointer;
    }
    .PageBox {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      color: #FFFFFF;
      padding: 7px;
    }
    div {
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
}
