.side-navbar {
    width: 250px;
    height: 100%;
    position: fixed;
    margin-left: -300px;
    background-color: #222C4A;
    transition: 0.5s;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #ffffff26;
}

.my-container {
    transition: 0.4s;
}

.active-nav {
    margin-left: 0;
}

/* for main section */
.active-cont {
    margin-left: 250px;
}

#menu-btn {
    background-color: #100901;
    color: #fff;
    margin-left: -62px;
}

.my-container .inputItem {
    /* border-radius: 2rem; */
    padding: 2px 20px;
}

.side-navbar{
    border-radius: 10px solid white  !important;
}