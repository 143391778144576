@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --c-gray-900: #000000;
  --c-gray-800: #1f1f1f;
  --c-gray-700: #2e2e2e;
  --c-gray-600: #313131;
  --c-gray-500: #969593;
  --c-gray-400: #a6a6a6;
  --c-gray-300: #bdbbb7;
  --c-gray-200: #f1f1f1;
  --c-gray-100: #ffffff;

  --c-green-500: #45ffbc;
  --c-lightgreen-500: #4bfafa;
  --c-olive-500: #e3ffa8;

  --c-white: var(--c-gray-100);

  --c-text-primary: var(--c-gray-100);
  --c-text-secondary: var(--c-gray-200);
  --c-text-tertiary: var(--c-gray-500);
}

.CardData {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 10px;
  transition: 0.25s ease;
  // -webkit-box-shadow: 2px 4px 10px rgba(0,0,0,0.47);
  &:hover {
    transform: translateY(-5px);
  }
  &.one {
    background-color: var(--c-green-500);
    &:focus-within {
      box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-green-500);
    }
  }
  &.two {
    background-color: var(--c-gray-300);
    &:focus-within {
      box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-300);
    }
  }
  &.three {
    background-color: var(--c-olive-500);
    &:focus-within {
      box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-olive-500);
    }
  }
  &.four {
    background-color: var(--c-lightgreen-500);
    &:focus-within {
      box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-300);
    }
  }
  // box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
  border-radius: 5px;
  height: 150px;
  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    .CardData-heading {
      font-weight: 600;
      color: rgb(34, 33, 32);
      // font-size: x-large;
    }
    .CardData-value {
      font-size: 2rem;
      font-weight: 500;
      color: rgb(34, 33, 32);
    }
    .CardData-view {
      text-decoration: underline;
      cursor: pointer;
      color: rgb(34, 33, 32);
      font-weight: 500;
    }
    .CardData-parentage {
      display: flex;
      font-size: 1.3rem;
      align-items: center;
      font-weight: 700;
      &.positive {
        color: rgb(40, 178, 40);
      }
      &.negative {
        color: rgb(34, 33, 32);
      }
    }
    .CardData-icon {
      padding: 4px;
      align-self: flex-end;
      color: inherit;
      border-color: inherit;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.25s ease;
      flex-shrink: 0;

      &:hover {
        color: var(--c-gray-900);
      }
      &:focus {
        background-color: transparent;
        color: var(--c-gray-900);
        i {
          transform: none;
        }
      }
    }
  }
}
// .tile {
// 	padding: 1rem;
// 	border-radius: 8px;
// 	background-color: var(--c-olive-500);
// 	color: var(--c-gray-900);
// 	min-height: 100px;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: space-between;
// 	position: relative;
// 	transition: 0.25s ease;

// 	&:hover {
// 		transform: translateY(-5px);
// 	}

// 	&:focus-within {
// 		box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-olive-500);
// 	}

// 	&:nth-child(2) {
// background-color: var(--c-green-500);
// &:focus-within {
// 	box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-green-500);
// }
// 	}
// 	&:nth-child(3) {
// 		background-color: var(--c-gray-300);
// 		&:focus-within {
// 			box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-300);
// 		}
// 	}

// 	a {
// 		text-decoration: none;
// 		display: flex;
// 		align-items: center;
// 		justify-content: space-between;
// 		font-weight: 600;
// 		color: var(--c-gray-900);
// 		.icon-button {
// 			color: inherit;
// 			border-color: inherit;
// 			&:hover{
// 				color: var(--c-gray-900);
// 			}
// 			&:focus {
// 				background-color: transparent;
// 				color: var(--c-gray-900);
// 				i {
// 					transform: none;
// 				}
// 			}
// 		}

// 		&:focus {
// 			box-shadow: none;
// 		}

// 		&:after {
// 			content: "";
// 			display: block;
// 			position: absolute;
// 			top: 0;
// 			left: 0;
// 			right: 0;
// 			bottom: 0;
// 		}
// 	}
// }

// .tile-header {
// 	display: flex;
// 	align-items: center;
// 	i {
// 		font-size: 2.5em;
// 	}

// 	h3 {
// 		display: flex;
// 		flex-direction: column;
// 		line-height: 1.375;
// 		margin-left: 0.5rem;
// 		span:first-child {
// 			font-weight: 600;
// 		}

// 		span:last-child {
// 			font-size: 0.825em;
// 			font-weight: 200;
// 		}
// 	}
// }
// https://html-css-js.com/css/generator/
